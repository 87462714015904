import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VTabs, {
    attrs: {
      "vertical": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTab, {
    key: "generalTab"
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-information ")]), _vm._v(" Allgemein ")], 1), _c(VTab, {
    key: "totalListTab"
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-format-list-bulleted ")]), _vm._v(" Liste aller Einträge ")], 1), _c(VTabItem, {
    key: "generalTab",
    staticClass: "mx-4 mt-3"
  }, [_c(VCard, {
    attrs: {
      "flat": ""
    }
  }, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "small": "",
      "disabled": _vm.userCountsLoading
    },
    on: {
      "click": function ($event) {
        return _vm.refreshGeneralData();
      }
    }
  }, [_c(VIcon, [_vm._v(" mdi-refresh ")]), _vm._v(" Aktualisieren ")], 1), _c(VRow, {
    staticClass: "mt-3",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('TelemetryCard', {
    attrs: {
      "title": "Benutzer",
      "subtitle": "24h / Woche / Monat",
      "body": _vm.formattedUserCounts,
      "loading": _vm.userCountsLoading
    }
  }), _c('TelemetryCard', {
    staticClass: "ml-3",
    attrs: {
      "title": "App-Zugriffe",
      "subtitle": "24h / Woche / Monat",
      "body": _vm.formattedAccessCounts,
      "loading": _vm.accessCountsLoading
    }
  }), _c('TelemetryCard', {
    staticClass: "ml-3",
    attrs: {
      "title": "Fehlgeschl. Logins",
      "subtitle": "24h / Woche / Monat",
      "body": _vm.formattedFailedAuthCounts,
      "loading": _vm.failedAuthCountsLoading
    }
  })], 1), _c('div', {
    staticClass: "mt-8 text-h6"
  }, [_vm._v("Zugriffe per App")]), _c(VCard, {
    staticClass: "mt-1",
    staticStyle: {
      "overflow-y": "scroll"
    },
    attrs: {
      "flat": "",
      "loading": _vm.appAccessCountsLoading,
      "max-height": "25vh"
    }
  }, [_c(VSimpleTable, {
    attrs: {
      "dense": ""
    }
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("App")]), _c('th', [_vm._v("Lizensiert")]), _c('th', [_vm._v("letze 24h")]), _c('th', [_vm._v("vergangene Woche")]), _c('th', [_vm._v("vergangenes Monat")])])]), _c('tbody', _vm._l(_vm.appAccessCounts, function (app) {
    return _c('tr', {
      key: app.appName
    }, [_c('td', [_vm._v(_vm._s(app.appName))]), _c('td', [_vm._v(_vm._s(app.licensed))]), _c('td', [_vm._v(_vm._s(app.counts.lastDay))]), _c('td', [_vm._v(_vm._s(app.counts.lastWeek))]), _c('td', [_vm._v(_vm._s(app.counts.lastMonth))])]);
  }), 0)])], 1)], 1)], 1), _c(VTabItem, {
    key: "totalListTab",
    staticClass: "mx-4 mt-3"
  }, [_c(VCard, {
    attrs: {
      "flat": ""
    }
  }, [_c(VRow, {
    staticClass: "d-inline-flex",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Nach Benutzer filtern"
    },
    model: {
      value: _vm.filterUser,
      callback: function ($$v) {
        _vm.filterUser = $$v;
      },
      expression: "filterUser"
    }
  }), _c(VSelect, {
    staticClass: "ml-4",
    attrs: {
      "label": "Benutzer",
      "disabled": false == _vm.filterUser,
      "items": _vm.telemetryUsers
    },
    model: {
      value: _vm.selectedTelemtryUser,
      callback: function ($$v) {
        _vm.selectedTelemtryUser = $$v;
      },
      expression: "selectedTelemtryUser"
    }
  }), _c(VBtn, {
    staticClass: "ml-2",
    attrs: {
      "fab": "",
      "x-small": "",
      "disabled": false == _vm.filterUser
    },
    on: {
      "click": function ($event) {
        return _vm.refreshTelemetryUsers();
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-refresh ")])], 1), _c(VBtn, {
    staticClass: "ml-14",
    attrs: {
      "rounded": "",
      "color": "primary",
      "disabled": _vm.totalListLoadButtonDisabled
    },
    on: {
      "click": function ($event) {
        return _vm.loadTotalList();
      }
    }
  }, [_vm._v("  Laden  ")])], 1), _c(VDataTable, {
    attrs: {
      "headers": _vm.totalListHeaders,
      "items": _vm.totalListItems,
      "loading": _vm.totalListLoading,
      "dense": ""
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };