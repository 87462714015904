export default {
  props: {
    header: {
      type: String,
      required: true
    },
    subheader: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    navigateBack() {
      this.$router.go(-1);
    }
  }
};