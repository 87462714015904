import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VCard, {
    staticClass: "px-3",
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VCardTitle, [_vm._v(" " + _vm._s(this.title) + " ")]), _c(VCardSubtitle, {
    staticClass: "text-caption"
  }, [_vm._v(" " + _vm._s(this.subtitle) + " ")]), _c(VCardText, {
    staticClass: "body-1"
  }, [_vm._v(" " + _vm._s(this.body) + " ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };