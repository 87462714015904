import axios from 'axios'

export default {
  getBackendInformation() {
    return axios
      .get(`/v1/dev/data/backendInformation`)
      .then(response => response.data)
  },

  getJwtInformation() {
    return axios
      .get(`v1/dev/data/jwtInformation`)
      .then(response => response.data)
  },

  getUserCounts() {
    return axios
      .get(`v1/dev/telemetry/user-counts`)
      .then(response => response.data)
  },

  getAccessCounts() {
    return axios
      .get(`v1/dev/telemetry/access-counts`)
      .then(response => response.data)
  },

  getFailedAuthCounts() {
    return axios
      .get(`v1/dev/telemetry/failed-auth-counts`)
      .then(response => response.data)
  },

  getAppAccessCounts() {
    return axios
      .get(`v1/dev/telemetry/app-access-counts`)
      .then(response => response.data)
  },

  /// Fetches a list of all telemetry users
  getUsers() {
    return axios.get(`v1/dev/telemetry/users`).then(response => response.data)
  },

  /// Loads the total list. User can be NULL to fetch all
  getTotalList(user) {
    return axios
      .get(
        `v1/dev/telemetry/entries${
          user != null && user != '' ? '?user=' + user : ''
        }`
      )
      .then(response => response.data)
  },
  getClientLocation(ipAddress) {
    return fetch(
      `http://ip-api.com/json/${ipAddress}?fields=20183039`
    ).then(response => response.json())
  },
  getClientIpAddress() {
    return fetch('https://api.ipify.org?format=json').then(response =>
      response.json()
    )
  }
}
