import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "d-flex flex-column align-stretch fill-height"
  }, [_c('SiteHeaderComponent', {
    attrs: {
      "header": "DEV-Portal"
    }
  }), _c(VCard, {
    staticClass: "my-8 elevation-0",
    attrs: {
      "loading": _vm.loading
    }
  }, [_c(VTabs, {
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTab, {
    key: "backendTab"
  }, [_vm._v("Backend-Information")]), _c(VTab, {
    key: "jwtTab"
  }, [_vm._v("JWT-Information")]), _c(VTab, {
    key: "clientTab"
  }, [_vm._v("Client-Informationen")]), _c(VTab, {
    key: "telemetryTab"
  }, [_vm._v("Telemetrie")])], 1), _c(VTabsItems, {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTabItem, {
    key: "backendTab"
  }, [_c(VDataTable, {
    staticClass: "mx-8 mt-2",
    attrs: {
      "headers": _vm.tableHeaders,
      "items": _vm.backendTableData,
      "hide-default-footer": true
    },
    scopedSlots: _vm._u([{
      key: "item.value",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(item.dateTime === true ? _vm.formatDateTime(item.value) : item.value) + " ")];
      }
    }])
  })], 1), _c(VTabItem, {
    key: "jwtTab"
  }, [_c(VDataTable, {
    staticClass: "mx-8 mt-2",
    attrs: {
      "headers": _vm.tableHeaders,
      "items": _vm.jwtTableData,
      "hide-default-footer": true
    },
    scopedSlots: _vm._u([{
      key: "item.value",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(item.dateTime === true ? _vm.formatDateTime(item.value) : item.value) + " ")];
      }
    }])
  })], 1), _c(VTabItem, {
    key: "clientTab"
  }, [_c(VDataTable, {
    staticClass: "mx-8 mt-2",
    attrs: {
      "headers": _vm.tableHeaders,
      "items": _vm.clientTableData,
      "hide-default-footer": true
    },
    scopedSlots: _vm._u([{
      key: "item.value",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(item.dateTime === true ? _vm.formatDateTime(item.value) : item.value) + " ")];
      }
    }])
  })], 1), _c(VTabItem, {
    key: "telemetryTab"
  }, [_c('TelemetryTab')], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };