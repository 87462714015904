import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "mt-8"
  }, [_c(VLayout, [_c(VFlex, {
    staticClass: "d-flex justify-center"
  }, [_c('h1', {
    staticClass: "header-header"
  }, [_vm._v(" " + _vm._s(_vm.header) + " ")]), _c('h4', {
    staticClass: "text-center header-subheader"
  }, [_vm._v(" " + _vm._s(_vm.subheader) + " ")])])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };