import TelemetryCard from './TelemetryCard.vue';
import devService from '@/services/devPortal/devService.js';
export default {
  components: {
    TelemetryCard
  },
  data: () => ({
    tab: null,
    // General props
    userCountsLoading: false,
    userCounts: {
      lastDay: '-',
      lastMonth: '-',
      lastWeek: '-'
    },
    accessCountsLoading: false,
    accessCounts: {
      lastDay: '-',
      lastMonth: '-',
      lastWeek: '-'
    },
    failedAuthCountsLoading: false,
    failedAuthCounts: {
      lastDay: '-',
      lastMonth: '-',
      lastWeek: '-'
    },
    appAccessCountsLoading: false,
    appAccessCounts: [],
    // Total list props
    filterUser: false,
    telemetryUsers: [],
    selectedTelemtryUser: null,
    totalListLoading: false,
    totalListHeaders: [{
      text: 'ID',
      value: 'id'
    }, {
      text: 'Username',
      value: 'username'
    }, {
      text: 'Timestamp',
      value: 'timestamp'
    }, {
      text: 'Sektion',
      value: 'section'
    }, {
      text: 'Aktion',
      value: 'action'
    }, {
      text: 'Details',
      value: 'description'
    }],
    totalListItems: []
  }),
  computed: {
    formattedUserCounts() {
      return this.userCounts.lastDay + ' / ' + this.userCounts.lastWeek + ' / ' + this.userCounts.lastMonth;
    },
    formattedAccessCounts() {
      return this.accessCounts.lastDay + ' / ' + this.accessCounts.lastWeek + ' / ' + this.accessCounts.lastMonth;
    },
    formattedFailedAuthCounts() {
      return this.failedAuthCounts.lastDay + ' / ' + this.failedAuthCounts.lastWeek + ' / ' + this.failedAuthCounts.lastMonth;
    },
    totalListLoadButtonDisabled() {
      if (this.filterUser) return this.selectedTelemtryUser == null;
      return false;
    }
  },
  methods: {
    loadGeneralData() {
      this.userCountsLoading = true;
      this.accessCountsLoading = true;
      this.failedAuthCountsLoading = true;
      this.appAccessCountsLoading = true;
      devService.getUserCounts().then(data => {
        this.userCounts = data;
      }).finally(() => {
        this.userCountsLoading = false;
      });
      devService.getAccessCounts().then(data => {
        this.accessCounts = data;
      }).finally(() => {
        this.accessCountsLoading = false;
      });
      devService.getFailedAuthCounts().then(data => {
        this.failedAuthCounts = data;
      }).finally(() => {
        this.failedAuthCountsLoading = false;
      });
      devService.getAppAccessCounts().then(data => {
        this.appAccessCounts = data;
      }).finally(() => {
        this.appAccessCountsLoading = false;
      });
    },
    refreshGeneralData() {
      this.loadGeneralData();
    },
    loadTotalListMasterData() {
      devService.getUsers().then(data => {
        this.telemetryUsers = data;
      });
    },
    refreshTelemetryUsers() {
      this.loadTotalListMasterData();
    },
    loadTotalList() {
      this.totalListLoading = true;
      devService.getTotalList(this.filterUser == true ? this.selectedTelemtryUser : null).then(data => {
        this.totalListItems = data;
      }).finally(() => {
        this.totalListLoading = false;
      });
    }
  },
  mounted() {
    this.loadGeneralData();
    this.loadTotalListMasterData();
  }
};