import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue';
import devService from '@/services/devPortal/devService.js';
import dateTimeFormatService from '@/services/formatters/dateTimeFormatService.js';
import TelemetryTab from '../../components/devPortal/TelemetryTab.vue';
export default {
  components: {
    SiteHeaderComponent,
    TelemetryTab
  },
  data: () => ({
    tab: null,
    loading: true,
    backendInformation: {},
    jwtInformation: {},
    clientInformation: {
      ipAddress: null,
      geolocation: {}
    },
    tableHeaders: [{
      text: 'Eigenschaft',
      align: 'start',
      sortable: false,
      value: 'name'
    }, {
      text: 'Wert',
      align: 'start',
      sortable: false,
      value: 'value'
    }]
  }),
  computed: {
    backendTableData() {
      const bi = this.backendInformation;
      return [{
        name: 'Backend Startzeitpunkt',
        value: bi.startTime,
        dateTime: true
      }, {
        name: 'Backend Buildzeitpunkt',
        value: bi.buildTime,
        dateTime: true
      }, {
        name: 'Systemzeit',
        value: bi.systemTime,
        dateTime: true
      }, {
        name: 'Hostname',
        value: bi.hostname,
        dateTime: false
      }, {
        name: 'PID (Prozess)',
        value: bi.pid,
        dateTime: false
      }, {
        name: 'Host Username (Prozess)',
        value: bi.hostUsername,
        dateTime: false
      }];
    },
    jwtTableData() {
      const jwt = this.jwtInformation;
      return [{
        name: 'Token ausgestellt am',
        value: jwt.issuedAt,
        dateTime: true
      }, {
        name: 'Token gültig bis',
        value: jwt.expiringAt,
        dateTime: true
      }, {
        name: 'Dauer der Restgültigkeit',
        value: jwt.validDuration != null ? dateTimeFormatService.formatISODuration(jwt.validDuration) : '-',
        dateTime: false
      }, {
        name: 'Benutzer',
        value: jwt.username,
        dateTime: false
      }, {
        name: 'Token abgelaufen?',
        value: jwt.expired == true ? 'ja' : 'nein',
        dateTime: false
      }];
    },
    clientTableData() {
      return [{
        name: 'Client IP-Adresse',
        value: this.clientInformation.ipAddress,
        dateTime: false
      }];
    }
  },
  methods: {
    loadClientInformation() {
      this.loading = true;
      this.loadClientInformations().finally(() => this.loading = false);
    },
    async loadClientInformations() {
      await devService.getClientIpAddress().then(data => {
        this.clientInformation.ipAddress = data.ip;
      });
      devService.getClientLocation(this.clientInformation.ipAddress).then(data => {
        this.clientInformation.geolocation = data;
      });
    },
    loadBackendInformation() {
      this.loading = true;
      devService.getBackendInformation().then(data => {
        this.backendInformation = data;
      }).finally(() => {
        this.loading = false;
      });
    },
    loadJwtInformation() {
      this.loading = true;
      devService.getJwtInformation().then(data => {
        this.jwtInformation = data;
        this.loading = false;
      });
    },
    formatDateTime(value) {
      return dateTimeFormatService.formatGermanDateTime(value);
    }
  },
  mounted() {
    this.loadBackendInformation();
    this.loadJwtInformation();
    this.loadClientInformation();
  }
};