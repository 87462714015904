export default {
  formatGermanDateTime(dateString) {
    if (dateString == null || dateString == '') return '-'

    return new Intl.DateTimeFormat('de-AT', {
      dateStyle: 'medium',
      timeStyle: 'medium'
    }).format(new Date(dateString))
  },

  formatISODuration(durationString) {
    const d = this.getDurationByISOString(durationString)
    return `${this.padTwoDigits(String(d.hours))}:${this.padTwoDigits(
      String(d.minutes)
    )}:${this.padTwoDigits(String(d.seconds))}`
  },

  getDurationByISOString(durationString) {
    var iso8601DurationRegex = /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/
    var matches = durationString.match(iso8601DurationRegex)

    return {
      sign: matches[1] === undefined ? '+' : '-',
      years: matches[2] === undefined ? 0 : matches[2],
      months: matches[3] === undefined ? 0 : matches[3],
      weeks: matches[4] === undefined ? 0 : matches[4],
      days: matches[5] === undefined ? 0 : matches[5],
      hours: matches[6] === undefined ? 0 : matches[6],
      minutes: matches[7] === undefined ? 0 : matches[7],
      seconds: matches[8] === undefined ? 0 : matches[8]
    }
  },

  padTwoDigits(number) {
    return ('00' + number).slice(-2)
  }
}
